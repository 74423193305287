#fun-facts {
  padding-bottom: 135px; }

#fun-facts-list li {
  line-height: 2rem; }

@media screen and (max-width: 576px) {
  #fun-facts-photo-div {
    display: flex;
    justify-content: space-between; } }

@media screen and (min-width: 576px) {
  #fun-facts-photo-div {
    display: flex;
    justify-content: flex-end; } }

figcaption {
  text-align: center;
  font-size: 0.8rem; }

.bucket-list-icon {
  font-size: 1.2rem;
  vertical-align: middle; }

#bucket-list {
  list-style: none;
  counter-reset: list-number;
  padding-left: 1rem; }

#bucket-list li {
  counter-increment: list-number;
  line-height: 2rem; }

#bucket-list li:before {
  content: counter(list-number) "."; }
