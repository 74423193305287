@import '../../custom';
@import "bootstrap/scss/bootstrap";

$xs: 576px;
$sm: 768px;

#home {
    padding-bottom: 135px;
}

#home-div {
    @media screen and (max-width: $xs) {
        align-items: center;
    }
}

#home-profile-image {
    @media screen and (max-width: $xs) {
        width: 50%;
        text-align: center;
    }
    // @media screen and (min-width: $xs) {
    //     width: 80%;
    // }
}