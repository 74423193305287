$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375)  //150px
  ),
  $spacers
);

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";