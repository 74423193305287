.citation-text {
  padding-left: 44px;
  text-indent: -44px; }

#projects {
  padding-bottom: 135px; }

.wrap-link {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }
